import React from "react";
import Seo from "../components/seo";
import {Link} from "gatsby";
import "../styles/404.css";

const PageNotFound = () => {
    return (
        <div className="not-found-container">
            <Seo title="404 - Page not found" description="The page you were looking for does not exist"/>
            <h1 className="header">The page you were looking for does not exist</h1>
            <h3 className="header">(Unless you were looking for the 404 page)</h3>
            <Link className="back" to="/">RETURN HOME</Link>
        </div>
    )
};

export default PageNotFound;
